import React, { Fragment } from 'react';
import ThumbTimestamp from '@/components/video/ThumbTimestamp';
import NowPlayingOverlay from '@/components/video/NowPlayingOverlay';
import Thumb from '@/components/thumb/Thumb';
import ContentItemIcon from '@/components/icons/ContentItemIcon';
import SponsoredBy from '@/components/ads/sponsored/SponsoredBy';
import WidgetDate from '@/components/widgets/WidgetDate';
import WidgetAuthor from '@/components/widgets/WidgetAuthor';
import { useVideoWidgetContext } from './VideoWidgetContext';
import { number, object } from 'prop-types';
import './VideoPlaylistItem.scss';
import { videoPlaylistClassActive } from './videoConstants';

/**
 * @function VideoPlaylistItem
 * @description displays a list of video thumbs
 * @param {Object} item video thumb info
 * @param {Number} index
 * @param {Number} videoNum current active video index
 * @param {Function} handleClick
 * @param {Boolean} [isSponsored]
 * @param {Object} [analyticsData]
 * @return {React.ReactElement}
 */

const VideoPlaylistItem = ({ item, index }) => {
	if (!item?.reference || index === undefined) return null;
	const { reference } = item;
	const {
		authorBylineEnabled,
		timestampsEnabled,
		videoNum,
		handleClick,
		isCurated,
		isSponsored,
		analyticsData
	} = useVideoWidgetContext();

	const { branding, publishDate, authors } = reference;

	return (
		<Fragment>
			{item.reference && (
				<button
					data-hook="video-playlist-item-button"
					onClick={() => {
						handleClick(index);
					}}
					className={`video-playlist__item text--sm ${
						videoNum === index ? videoPlaylistClassActive : ''
					}`}
					{...analyticsData}
				>
					<div className="video-playlist__img-container">
						<Thumb
							thumbnail={item.displayImage}
							width="176"
							height="99"
							type="16x9"
							width2x="206"
							withLink={false}
						>
							<div className="video-playlist__thumbnail-items">
								{videoNum === index ? (
									<NowPlayingOverlay />
								) : (
									<Fragment>
										<ContentItemIcon
											type={item.linkType}
											className="video-playlist__icon"
										/>
										<ThumbTimestamp duration={item.reference.duration} />
									</Fragment>
								)}
							</div>
						</Thumb>
					</div>
					<div data-hook="video-playlist-title" className="video-playlist__title">
						{item.reference.shortTitle ? item.reference.shortTitle : item.displayTitle}
						{authorBylineEnabled && <WidgetAuthor authors={authors} />}
						{timestampsEnabled && <WidgetDate date={publishDate} />}
					</div>
					{isSponsored && isCurated && branding && <SponsoredBy {...branding} />}
				</button>
			)}
		</Fragment>
	);
};

VideoPlaylistItem.propTypes = {
	isSponsored: false
};

VideoPlaylistItem.propTypes = {
	item: object.isRequired,
	index: number.isRequired
};

VideoPlaylistItem.displayName = 'VideoPlaylistItem';

export default VideoPlaylistItem;
