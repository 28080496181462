import React from 'react';
import Translate from '@/components/i18n/Translate';
import './NowPlayingOverlay.scss';

/**
 * @function NowPlayingOverlay
 * @description "Now Playing" overlay that appears over Related Videos thumbnails on the Video Detail page
 * @returns {React.ReactElement}
 */
const NowPlayingOverlay = () => {
	return (
		<div className="video-thumb__overlay">
			<div className="video-thumb__overlay-bkg"></div>
			<span className="video-thumb__overlay-caption has-text-shadow">
				<Translate word="NOW_PLAYING" />
			</span>
		</div>
	);
};

NowPlayingOverlay.displayName = 'NowPlayingOverlay';

export default NowPlayingOverlay;
