import React, { Fragment } from 'react';
import { useVideoWidgetContext } from './VideoWidgetContext';
import VideoPlaylistItem from './VideoPlaylistItem';
import Slider from '@/components/slider/Slider';
import './VideoPlaylist.scss';

/**
 * @function VideoPlaylist
 * @description displays a list of video thumbs
 * @param {Array} list video thumbs info
 * @param {Number|String} [playlistHeight]
 * @param {Object} [playlistEl]
 * @return {React.ReactElement}
 */

const VideoPlaylist = () => {
	const { list, playlistHeight, playlistEl } = useVideoWidgetContext();

	if (!list?.length || !Array.isArray(list)) return null;

	return (
		<div className="video-playlist__overlay-wrapper">
			<div className="video-playlist__wrapper">
				<section
					data-hook="video-playlist"
					className="video-playlist"
					style={{
						height: playlistHeight
					}}
					ref={playlistEl}
				>
					<Slider arrowClassName="arrow-sm" slideWidth={186}>
						{list?.map((item, index) => {
							return (
								<Fragment key={`playlist-${index}`}>
									<VideoPlaylistItem item={item} index={index} />
								</Fragment>
							);
						})}
					</Slider>
				</section>
			</div>
			<div className="video-playlist__overlay"></div>
		</div>
	);
};

VideoPlaylist.displayName = 'VideoPlaylist';

export default VideoPlaylist;
