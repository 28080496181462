/**
 * @const videoPlaylistClassActive
 * @description playlist item will have this class if it is the current video playing
 */
export const videoPlaylistClassActive = 'video-playlist__item--active';

/**
 * @const videoWidgetNoTitleClass
 * @description class name when a video widget has no header or headerImage
 */
export const videoWidgetNoTitleClass = 'widget__video--no-title';

/**
 * @const videoWidgetDesktopViewClass
 * @description desktop class for vidoe widget
 */
export const videoWidgetDesktopViewClass = 'widget__video--desktop-view';

/**
 * @const videoWidgetThemes
 * @description stores the different theme configurations a video widget can have
 */
export const videoWidgetThemes = {
	VIDEO_STACKED_DARK: {
		isStacked: true, // activates stacked view; tablet view for both tablet & desktop
		themeClass: 'widget--dark' // widget color theme class
	},
	VIDEO_STACKED_LIGHT: {
		isStacked: true,
		themeClass: 'widget--light'
	},
	VIDEO_HORIZONTAL_DARK: {
		isStacked: false,
		themeClass: 'widget--dark'
	},
	VIDEO_HORIZONTAL_LIGHT: {
		isStacked: false,
		themeClass: 'widget--light'
	}
};

/**
 * @const videoWidgetDefaultTheme
 * @description the default layout theme for the video widget
 */
export const videoWidgetDefaultTheme = 'VIDEO_HORIZONTAL_LIGHT';
