import React from 'react';
import { formatDuration } from './videoHelpers';
import { string, number } from 'prop-types';
import './ThumbTimestamp.scss';

/**
 * @function ThumbTimestamp
 * @description creates a timestamp element used with video thumbnails
 * @param {Number} duration Length of video in seconds
 * @param {String} [className]
 * @returns {ReactElement}
 */
const ThumbTimestamp = ({ duration, className }) => (
	<time className={`${className} thumb-video-timestamp`}>{formatDuration(duration)}</time>
);

ThumbTimestamp.defaultProps = {
	duration: 0,
	className: ''
};

ThumbTimestamp.propTypes = {
	duration: number,
	className: string
};

ThumbTimestamp.displayName = 'ThumbTimestamp';

export default ThumbTimestamp;
