import { createContext, useContext } from 'react';

/**
 * @function VideoWidgetContext
 * @description creates a context for video widget
 * @param {Array} list array of playlist video items, from graphQL query
 * @param {Number} playlistHeight the height of the scrollable playlist; used in desktop horizontal view
 * @param {Object} playlistEl the element containing the playlist video items
 * @param {Function} handleClick playlist video item click callback
 * @param {Boolean} isCurated determine if sponsored byline should be displayed
 * @param {Number} isSponsored whether a video item is sponsored
 * @param {Object} analyticsData analytics data for click tracking
 * @return {Context}
 */
export const VideoWidgetContext = createContext({
	list: null,
	playlistHeight: 300,
	playlistEl: null,
	videoNum: 0,
	handleClick: null,
	isCurated: false,
	isSponsored: false,
	analyticsData: null,
	authorBylineEnabled: false,
	timestampsEnabled: false
});

export const useVideoWidgetContext = () => useContext(VideoWidgetContext);
