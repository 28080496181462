import React, {
	Fragment,
	Children,
	cloneElement,
	useState,
	useEffect,
	useRef,
	useContext
} from 'react';
import DockableVideoContext from './DockableVideoContext';
import { useInView } from 'react-intersection-observer';
import SvgImage from '@/components/icons/SvgImage';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import { useFeatureToggleContext } from '@/components/layout/page/PageContext';
import pauseIcon from '@/images/video-pause-mobile.svg';
import playIcon from '@/images/video-play-mobile.svg';
import closeIcon from '@/images/close-icon.svg';
import { object, string, oneOfType, number, bool } from 'prop-types';
import './DockableVideo.scss';

/**
 * @function DockableVideo
 * @description will wrap a video with dockable elemnts and handle the docking
 * @param {Object} props
 * @param {Array} props.children
 * @param {String} props.title - title of the video
 * @param {String} props.playerId - id of the player, unique to the page
 * @param {String | Number} [props.width] - width of video being wrapped
 * @param {String | Number} [props.height] - height of video being wrapped
 * @param {Function} [props.playerCallbacks] - pkd player callbacks that will be passed to the pdk player
 * @param {Boolean} [startDocked] - override the default settings and allow player to dock before playback begins; to avoid conflicts, only one player per page should be set to true
 * @returns {React.ReactElement}
 */
const DockableVideo = ({
	children,
	title,
	width,
	height,
	playerCallbacks,
	playerId,
	startDocked
}) => {
	if (!children || !title || playerId === '') return null;

	// tracks the ID of the actively docked player on the page
	const { dockedPlayerId, updateDockedPlayerId } = useContext(DockableVideoContext);
	// reference copy of the docked player ID so accurate data can be fetched in a video controller event
	const dockedVideoPlayerIdRef = useRef(dockedPlayerId);

	const [isPaused, setIsPaused] = useState(false);
	const [isStarted, setIsStarted] = useState(false);
	const [playerController, setPlayerController] = useState(null);
	const [mobileSubnavVisible, setMobileSubnavVisible] = useState(true);
	const [ref, inView] = useInView();
	const dockEl = useRef();
	const { GAMMA_PLAYER_ENABLED } = useParamToggleContext();
	const { GAMMA_PLAYER } = useFeatureToggleContext();

	// whether the player is able to dock;
	// player is able to dock after playback is initiated, and when there is no other player docked on the page
	const [canDock, setCanDock] = useState(startDocked);
	// flags the player as closed by the user; can't be docked again after closing
	const closedDock = useRef(false);

	const showPlayIcon = !isStarted || isPaused;

	const togglePlay = () => {
		if (playerController) {
			!isStarted && playerController.start();
			isStarted && playerController.pause(!isPaused);
		}
	};

	const closeClick = () => {
		// mark player as not able to dock anymore and close it
		setCanDock(false);
		closedDock.current = true;
		// pause playback
		playerController && playerController.pause(true);
		// clear the main docked player ID
		updateDockedPlayerId && updateDockedPlayerId('');
	};

	useEffect(() => {
		// if flagged to dock before playback begins, set the global dock player ID
		if (startDocked) {
			updateDockedPlayerId(playerId);
		}
	}, []);

	useEffect(() => {
		// If the main player dock reference has been cleared
		// and this player has initiated playback and hasn't had a user close the dock
		if (dockedPlayerId === '' && isStarted && !closedDock.current) {
			// assign this player as the main player to dock
			updateDockedPlayerId && updateDockedPlayerId(playerId);
			dockedVideoPlayerIdRef.current = playerId;
			// enable docking for this widget
			setCanDock(true);
		} else {
			// else update reference with new docked player ID
			dockedVideoPlayerIdRef.current = dockedPlayerId;

			if (dockedPlayerId === playerId) {
				// enable docking for this widget
				setCanDock(true);
			}
		}
	}, [dockedPlayerId]);

	/**
	 * combining player callbacks passed into this component with the defaults.
	 * These will be passed into the pdk player.
	 * The defaults can be overriden by passing them in from the parent.
	 */
	const videoPlayerCallbacks = Object.assign(
		{
			onMediaPause: (e) => {
				setIsPaused(true);
			},
			onMediaUnpause: (e) => {
				setIsPaused(false);
			},
			onMediaLoadStart: (e) => {
				setIsStarted(true);
				/* Video docking control in case there are multiple videos on the page. */
				// If there is no other player assigned as the main player to dock
				// and this player hasn't previously had a docked player closed by the user
				if (dockedVideoPlayerIdRef.current === '' && !closedDock.current) {
					// assign this player as the main player to dock
					updateDockedPlayerId(playerId);
				}
			},
			getController: (c) => {
				setPlayerController(c);
			}
		},
		playerCallbacks
	);

	/**
	 * @function dispatchDockEvent
	 * @description dispatches an event to the pdk player letting it know the video is docked and !docked
	 */
	const dispatchDockEvent = () => {
		if (GAMMA_PLAYER_ENABLED || GAMMA_PLAYER) return;
		const pdkIframe = dockEl.current.querySelector('[data-hook="videoplayeriframe"]');
		pdkIframe.contentWindow.postMessage(
			{ name: 'OnDockable', docked: !inView },
			'https://player.theplatform.com'
		);
	};

	// wrapping this in use effect since we need
	// the document.getElementById of the iframe
	useEffect(() => dispatchDockEvent(), [inView]);

	useEffect(() => {
		const mobileSubnavEventListener = (event) => setMobileSubnavVisible(event.detail.isVisible);

		document.addEventListener('mobileSubnavEvent', mobileSubnavEventListener);

		return () => {
			document.removeEventListener('mobileSubnavEvent', mobileSubnavEventListener);
		};
	}, []);

	return (
		<div
			ref={ref}
			data-hook="dockable"
			className={`dockable${mobileSubnavVisible ? '' : ' mobile-subnav-collapsed'}`}
			{...{ style: { paddingTop: `${Math.floor((height / width) * 100)}%` } }}
		>
			<div
				data-hook="dockable-wrap"
				className={`dockable__wrap ${!inView && canDock ? 'dockable__wrap--dock' : ''}`}
			>
				<div
					ref={dockEl}
					className={`dockable__video ${
						!inView && canDock ? 'dockable__video--docked' : ''
					}`}
				>
					{Children.map(children, (child, index) =>
						cloneElement(child, {
							callbacks: videoPlayerCallbacks,
							useExternalController: true
						})
					)}
				</div>

				{!inView && canDock ? (
					<Fragment>
						<div className="dockable__title">{title}</div>
						<div className="dockable__controls">
							<div
								data-hook="dockable-pause"
								className="dockable__pause"
								tabIndex="0"
								role="button"
								onClick={togglePlay}
								onKeyPress={togglePlay}
							>
								{(showPlayIcon && <SvgImage image={playIcon} />) || (
									<SvgImage image={pauseIcon} />
								)}
							</div>
							<div
								data-hook="dockable-close"
								className="dockable__close"
								tabIndex="0"
								role="button"
								onClick={closeClick}
								onKeyPress={closeClick}
							>
								<SvgImage image={closeIcon} />
							</div>
						</div>
					</Fragment>
				) : null}
			</div>
		</div>
	);
};

// 16x9 = 900 x 505 || 775 x 434 = pading-top: 56%
DockableVideo.defaultProps = {
	width: 900,
	height: 505,
	canDock: true,
	playerId: '',
	startDocked: false
};

DockableVideo.propTypes = {
	children: object.isRequired,
	title: string.isRequired,
	playerId: string.isRequired,
	width: oneOfType([string, number]),
	height: oneOfType([string, number]),
	playerCallbacks: object,
	startDocked: bool
};

DockableVideo.displayName = 'DockableVideo';

export default DockableVideo;
